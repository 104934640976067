import * as React from "react";

import { withTrans } from "../../i18n/withTrans";

import { pageTexts } from "../../content/brochure-summer-2022";

import brochureSummer2022Pdf from "../../files/brochure-summer-2022.pdf"; 

// components
import Layout from "../../components/layout";

class brochureSummer2022 extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
    this.content = pageTexts(this.t);
  }

  render() {
    return (
        <Layout pageName="brochure-summer-2022" whiteMenu={true}>
          <div className="d-flex justify-content-center pb-20 pt-150">
            <embed src={brochureSummer2022Pdf} width="800px" height="1150px" />
          </div>
        </Layout>    
    );
  }
}

export default withTrans(brochureSummer2022);
